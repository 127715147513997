import api from "../services/api";
import { getInfoUser } from "../services/auth";
const user = getInfoUser();


const ConfigWS = {
  broadcaster: 'pusher',
  key: '9cbababdd85a841674e2',
  forceTLS: true,
  disableStats: true,
  wsHost: process.env.REACT_APP_WBSOCKET_HOST || 'api.wfpay.com.br',
  wsPort: process.env.REACT_APP_WEBSOCKET_PORT || 6006,
  wssPort: process.env.REACT_APP_WEBSOCKET_PORT || 6006,
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        api.interceptors.request.use(async config => {
          config.headers['X-Socket-ID'] = socketId;
          return config;
        });
        api.post('broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name,
            token: user.token
        }).then(response => {
            callback(false, response.data);
        })
        .catch(error => {
            callback(true, error);
        })
      }
    }
  }
}

export default ConfigWS;
